import React from 'react';

function Hero({ hero }) {
  return (
    <div className="hero">
      <img src={hero.logo} alt="Logo" className="hero-logo" />
      <div className="hero-ribbon">
        <div className="hero-content">
          <h1>{hero.title}</h1>
        </div>
      </div>
      <div className="hero-image-container">
        <img src={hero.image} alt="Hero" className="hero-image" />
      </div>
    </div>
  );
}

export default Hero;