import React from 'react';

function Products({ products }) {
  return (
    <section className="products">
      <h2>それ、【０→１Supportコミュニティ】で解決出来ます</h2>
      <div className="product-list">
        {products.map((product, index) => (
          <div className="product" key={index}>
            <img src={product.image} alt={product.title} />
            <h3>{product.title}</h3>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Products;