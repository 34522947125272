import React from 'react';

function Hero({ hero }) {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: hero.styles.backgroundColor,
    color: hero.styles.textColor,
    padding: '2rem',
    margin: '2rem auto',
    maxWidth: '800px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const imageContainerStyle = {
    flex: '1',
    textAlign: 'center',
    marginRight: '2rem',
  };

  const textContainerStyle = {
    flex: '1',
    textAlign: 'center',
  };

  return (
    <div className="hero">
      <img src={hero.logo} alt="Logo" className="hero-logo" />
      <div className="hero-ribbon">
        <div className="hero-content">
          <h1>{hero.title}</h1>
        </div>
      </div>
      <div style={containerStyle}>
        <div className="hero-image-container" style={imageContainerStyle}>
          <img src={hero.image} alt="Hero" className="hero-image" />
        </div>
      <div style={textContainerStyle}>
        <h2>{hero.subtitle}</h2>
      </div>
    </div>
      </div>
      
  );
}

export default Hero;