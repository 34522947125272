import React from 'react';

function CTA({ cta }) {
  return (
    <section className="cta">
      <h2>{cta.title}</h2>
      <button className="cta-button">{cta.buttonText}</button>
    </section>
  );
}

export default CTA;