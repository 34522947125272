import React from 'react';

function Attention({ attention }) {
  const titleStyle = {
    color: '#333333',
  };

  const subtitleLines = attention.subtitle.split('\\n');

  return (
    <section className="attention">
      <div className="attention-ribbon">
        <div className="attention-content">
          <h1 style={titleStyle}>{attention.title}</h1>
        </div>
      </div>
      <div className="attention-container">
        <div className="attention-image-container">
          <img
            src={attention.imagePath}
            alt="Attention"
            className="attention-image"
          />
        </div>
        <div className="attention-text-container">
          {subtitleLines.map((line, index) => {
            if (line.startsWith('・')) {
              const [text, imagePath] = line.slice(1).split(' ');
              return (
                <div key={index}>
                  <p>{text}</p>
                  {imagePath && <img src={imagePath} alt={text} />}
                </div>
              );
            } else {
              return <p key={index}>{line}</p>;
            }
          })}
        </div>
      </div>
    </section>
  );
}

export default Attention;