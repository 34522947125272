import React from 'react';

const Eyecatch = () => {
  return (
    <div className="eyecatch">
      <img src='/images/eyecatch.png' alt="アイキャッチ画像" />
    </div>
  );
};

export default Eyecatch;