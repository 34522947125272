import React from 'react';

function Testimonials({ testimonials }) {
  return (
    <section className="testimonials">
      <h2>ユーザーの声</h2>
      {testimonials.map((testimonial, index) => (
        <div className="testimonial" key={index}>
          <img src={testimonial.image} alt={testimonial.name} className="user-image" />
          <p className="testimonial-text">{testimonial.text}</p>
          <p className="user-name">- {testimonial.name}</p>
        </div>
      ))}
    </section>
  );
}

export default Testimonials;