import React from 'react';
// import Header from './components/pages/0.Header';
import Attention from './components/pages/2.Attention';
import Features from './components/pages/3.Features';
import Products from './components/pages/4.Products';
import Testimonials from './components/pages/5.Testimonials';
import CTA from './components/pages/6.CTA';
import Footer from './components/pages/7.Footer';
import Eyecatch from './components/pages/1.Eyecatch';
import Hero1 from './components/pages/2.1Hero';
import Hero2 from './components/pages/2.2Hero';
import Hero3 from './components/pages/2.3Hero';
import './App.css';
import content from './content.json'

const { hero1, hero2, hero3 } = content;

function App() {
  return (
    <>
      {/* <Header /> */}
      <Eyecatch />
      {content.attention && <Attention attention={content.attention} />}
      {hero1 && <Hero1 hero={hero1} />}
      {hero2 && <Hero2 hero={hero2} />}
      {hero3 && <Hero3 hero={hero3} />}
      {content.features && <Features features={content.features} />}
      {content.products && <Products products={content.products} />}
      {content.testimonials && <Testimonials testimonials={content.testimonials} />}
      {content.cta && <CTA cta={content.cta} />}
      <Footer />
    </>
  );
}

export default App;