import React from 'react';

function Features({ features }) {
  return (
    <section className="features">
      <div className="features-ribbon">
        <div className="features-content">
          <h2>こんな不安抱えていませんか？</h2>
        </div>
      </div>
      <div className="features-container">
        {features.map((feature, index) => (
          <div className="feature" key={index}>
            <img src={feature.image} alt={feature.title} className="feature-image" />
            <div className="feature-content">
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Features;